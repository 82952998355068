import { BttcChainId } from 'types';

export const API_ENDPOINT_URLS = {
  // Todo: change when we have our backend
  [BttcChainId.MAINNET]: 'https://api.venus.io/api',
  [BttcChainId.TESTNET]: 'https://testnetapi.venus.io/api',
};

export const RPC_URLS: {
  [key: string]: string[];
} = {
  [BttcChainId.MAINNET]: ['https://rpc.ankr.com/bttc'],
  [BttcChainId.TESTNET]: ['https://pre-rpc.bt.io/'],
};

export const BITLEND_API_URL = 'https://api.bitlend.fi';
