import Path from 'constants/path';

import { MenuItem } from './types';

export const menuItems: MenuItem[] = [
  {
    href: Path.ROOT,
    // Translation key: do not remove this comment
    // t('layout.menuItems.dashboard')
    i18nKey: 'layout.menuItems.dashboard',
    i18nTitleKey: 'layout.menuItems.dashboard',
    icon: 'dashboard',
  },
  {
    href: Path.MARKETS,
    // Translation key: do not remove this comment
    // t('layout.menuItems.markets')
    i18nKey: 'layout.menuItems.markets',
    i18nTitleKey: 'layout.menuItems.markets',
    icon: 'market',
  },
  // {
  //   href: Path.RISK_MANAGEMENT,
  //   // Translation key: do not remove this comment
  //   // t('layout.menuItems.riskManagement')
  //   i18nKey: 'layout.menuItems.riskManagement',
  //   i18nTitleKey: 'layout.menuItems.riskManagement',
  //   icon: 'attention',
  // },
  // {
  //   href: Path.VAULTS,
  //   // Translation key: do not remove this comment
  //   // t('layout.menuItems.vaults')
  //   i18nKey: 'layout.menuItems.vaults',
  //   i18nTitleKey: 'layout.menuItems.vaults',
  //   icon: 'vault',
  // },
  // {
  //   href: Path.HISTORY,
  //   // Translation key: do not remove this comment
  //   // t('layout.menuItems.history')
  //   i18nKey: 'layout.menuItems.history',
  //   i18nTitleKey: 'layout.menuItems.history',
  //   icon: 'history',
  // },
  // {
  //   href: Path.VAULTS,
  //   // Translation key: do not remove this comment
  //   // t('layout.menuItems.history')
  //   i18nKey: 'layout.menuItems.vaults',
  //   i18nTitleKey: 'layout.menuItems.vaults',
  //   icon: 'vault',
  //   soon: true,
  // },
  {
    href: Path.GOVERNANCE,
    // Translation key: do not remove this comment
    // t('layout.menuItems.governance')
    i18nKey: 'layout.menuItems.governance',
    i18nTitleKey: 'layout.menuItems.governance',
    icon: 'vote',
    soon: true,
  },
  // {
  //   href: Path.XVS,
  //   // Translation key: do not remove this comment
  //   // t('layout.menuItems.blend')
  //   i18nKey: 'layout.menuItems.blend',
  //   i18nTitleKey: 'layout.menuItems.blend',
  //   icon: 'xvsOutlined',
  // },
  // {
  //   href: Path.CONVERT_VRT,
  //   // Translation key: do not remove this comment
  //   // t('layout.menuItems.convertVrt')
  //   // t('layout.menuItems.convertVrtTitle')
  //   i18nKey: 'layout.menuItems.convertVrt',
  //   i18nTitleKey: 'layout.menuItems.convertVrtTitle',
  //   icon: 'convert',
  // },
  // {
  //   href: 'https://prdt.finance/XVS',
  //   // Translation key: do not remove this comment
  //   // t('layout.menuItems.xvsPrediction')
  //   i18nKey: 'layout.menuItems.xvsPrediction',
  //   i18nTitleKey: 'layout.menuItems.xvsPrediction',
  //   icon: 'xvsPrediction',
  // },
];
